import React from 'react'
import { Link } from '../@gatsbystorefront/.gatsby-theme-storefront-shopify/utils/general'

export default function NotFoundPage() {
    return (
        <div class="gradient text-black min-h-screen flex items-center justify-center">
        <div class="container mx-auto p-4 flex flex-wrap items-center">
            <div class="w-full md:w-7/12 text-center p-4">
            <img src="https://cdn.shopify.com/s/files/1/0278/3612/1223/files/404.png?v=1612421695" alt="Not Found" />
            </div>
            <div class="w-full md:w-5/12 text-center md:text-left p-4 flex flex-col items-center justify-center md:items-start">
            <div class="text-6xl font-medium">404</div>
            <div class="text-xl md:text-3xl font-medium mb-4">Oops. This page has gone missing.</div>
            <div class="text-lg mb-8">You may have mistyped the address or the page may have moved.</div>
            <Link to="/" class="hover:no-underline"> 
                <div href="#" class="border border-black rounded p-2 w-32 text-center hover:no-underline">Go Home</div>
            </Link> 

            </div>
        </div>
        </div>
    )
}
